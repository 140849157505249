<template>
  <div :class="{ 'has-error': validation.hasError('price') }" class="form--group row">
    <div class="col-6 mb-1 mb-sm-0">
      <span class="layer-not--allowed" v-show="uncheckable"> </span>
      <div class="custom-control custom-checkbox my-2">
        <input
          @click="rentPeriodChange"
          type="checkbox"
          class="custom-control-input"
          :class="validation.hasError('price') ? 'is-invalid' : ''"
          :value="id"
          :id="'rentPrice-' + id"
          :checked="checked"
          name="rent_period_id[]"
        />
        <label class="custom-control-label" :for="'rentPrice-' + id">{{ name }}</label>
      </div>
    </div>
    <div class="col-6">
      <div class="input--group">
        <div class="append--left">
          S$
        </div>
        <cleave
          class="basic--input price--input"
          type="text"
          name="price[]"
          :value="price"
          @input="priceChange"
          :readonly="!checked"
          :class="validation.hasError('price') ? 'is-invalid' : ''"
          :options="cleaveOption"
          ref="price"
        />
      </div>
      <div class="val-error" v-if="validation.hasError('price')">
        {{ validation.firstError('price') }}
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { mapGetters } from 'vuex';
import Cleave from 'vue-cleave-component';
export default {
  name: 'rent-price-field',
  props: ['index', 'id', 'name', 'price', 'checked'],
  components: {
    Cleave,
  },
  mounted() {},
  data() {
    return {
      cleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedRentPeriodIds: 'v2/listingForm/sect1/basicInfo/selectedRentPeriodIds',
    }),
    uncheckable() {
      if (Object.keys(this.selectedRentPeriodIds).length === 0) {
        return false;
      } else {
        return (
          (this.selectedRentPeriodIds.includes(5) && this.id != 5) ||
          (!this.selectedRentPeriodIds.includes(5) && this.id == 5)
        );
      }
    },
  },
  watch: {
    checked() {
      this.validation.reset();
    },
  },
  validators: {
    price(value) {
      let self = this;
      if (self.checked) {
        return Validator.value(value).required(this.$i18n.t('errors.addListing.rentPrice.null'));
      }
    },
  },
  methods: {
    priceChange(value) {
      this.$store.commit('v2/listingForm/sect1/basicInfo/CHANGE_PRICE', {
        rentPeriodId: this.id,
        price: value,
      });
    },
    rentPeriodChange($event) {
      let self = this;
      self.$store.commit(
        'v2/listingForm/sect1/basicInfo/TOGGLE_CHECK_RENT_PERIOD',
        $event.target.value,
      );
    },
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style scoped></style>
